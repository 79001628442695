@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Roboto", sans-serif;
  font-optical-sizing: auto;
}

body {
  font-size: 16px;
  height: 100%;
}

/* Colours to be decided */
$themes: (
  light: (
    textColor: #000,
    logo: #1876f2,
    bg: white,
    bgSecondary: #f0f2f5,
    bgThird: #e4e6eb,
    bgForth: #f0f2f5,
    bgSoft: #f6f3f3,
    bgSoftHover: rgb(223 223 223),
    textColorSoft: #555,
    border: #ccced2,
    colorPrimary: #050505,
    colorSecondary: #65676b,
  ),
  dark: (
    textColor: whitesmoke,
    bg: #18191a,
    bgSecondary: #242526,
    bgThird: #3a3b3c,
    bgForth: #f0f2f5,
    logo: white,
    bgSoft: #333333,
    bgSoftHover: #404040,
    textColorSoft: rgb(138, 128, 128),
    border: #444,
    colorPrimary: #242526,
    colorSecondary: #b0b3b8,
  ),
);

/* Universal Colours */
:root {
  --purple: #67066f;
  --purple2: #c817e35f;
  --purple3: #ca0fdb;
  --gold: #b6903f;
  --white: #f0f2f5;
  --white2: #e4e6eb;
  --black: #000000;
  --grey: rgb(128, 128, 128);

  --shadow1: rgba(0, 0, 0, 0.2);
  --shadow2: rgba(0, 0, 0, 0.1);
  --shadow3: rgba(0, 0, 0, 0.38);
  --shadow-inset: rgba(255, 255, 255, 0.5);
}

/* Mixins */

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
