.home {
  position: relative;
  .btn {
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
    border: 1px solid var(--purple);
    color: var(--purple);
    width: 50%;
    padding: 10px;
    transition: background-color 0.4s;
    margin-bottom: 2rem;
    font-size: 1.5rem;

    &:hover {
      color: white;
      background-color: var(--purple);
    }

    @media screen and (max-width: 600px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 400px) {
      font-size: 1rem;
    }
  }

  .our-credentials {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    h1 {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 800;
      user-select: none;
    }

    &-logo-container {
      display: flex;
      width: 100%;
      gap: 10px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .img-wrapper {
        display: flex;
        flex: 1;
        padding: 1rem;
        flex-direction: column;
        max-width: 250px;
        min-width: 250px;
        align-items: center;
        gap: 10px;

        img {
          width: 250px;
          user-select: none;
          height: 100px;
        }

        p {
          font-weight: bold;
          font-size: 0.8rem;
          text-align: center;
          user-select: none;
        }
        br {
          user-select: none;
        }
      }
    }
  }

  .section {
    min-height: 30vh;
    display: flex;
    position: relative;
    padding: 2rem 0;
    border-top: 1px solid black;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 400px) {
      flex-direction: column;
    }

    &-black {
      background-color: black;
      gap: 20px;
      flex-direction: column;

      .black-header {
        color: var(--gold);
        font-size: 2rem;

        @media screen and (max-width: 400px) {
          font-size: 1.5rem;
        }
      }

      .grid-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 20px;

        .grid-box {
          padding: 1rem;
          flex: 1;
          max-width: 80%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          color: white;
          gap: 10px;
          min-width: 300px;

          @media screen and (max-width: 400px) {
            min-width: 230px;
          }

          .section-icon {
            z-index: unset;
            position: relative;
            bottom: unset;
            width: 64px;
            height: 64px;
          }

          p {
            font-size: 1.5rem;
          }
        }
      }
    }

    &-white {
      background-color: white;

      path {
        fill: white;
        min-height: 250px;
      }
    }

    &-left {
      padding: 0 2rem;
      flex: 3;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      min-width: 350px;

      @media screen and (max-width: 400px) {
        min-width: unset;
      }

      h1 {
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 1px;
        margin: 2rem 0;

        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }

      span {
        font-size: 1.5rem;
        width: 90%;
        &:nth-of-type(2) {
          margin-bottom: 2rem;
        }

        @media screen and (max-width: 600px) {
          font-size: 1rem;
        }
      }

      img {
        width: 200px;
      }
    }
    .section-right {
      flex: 5;
      min-width: 60%;
      padding: 2rem 0 2rem 2rem;
      width: 100%;

      @media screen and (max-width: 891px) {
        padding: 2rem;
      }

      @media screen and (max-width: 600px) {
        padding: 0;
        min-width: unset;
      }

      @media screen and (max-width: 400px) {
      }

      img {
        width: 100%;
        height: 50vh;
        object-fit: cover;

        @media screen and (max-width: 600px) {
          height: 30vh;
        }
      }
    }

    svg {
      position: absolute;
      bottom: -60%;
      z-index: 10;

      @media screen and (max-width: 1400px) {
        bottom: -35%;
      }
      @media screen and (max-width: 820px) {
        bottom: -20%;
      }
      @media screen and (max-width: 475px) {
        bottom: -15%;
      }
      @media screen and (max-width: 360px) {
        bottom: -10%;
      }
    }
  }

  .section-full-left {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 750px) {
      flex-direction: column;
    }
    &-text-col-center {
      justify-content: center !important;
      align-items: center !important;

      h1 {
        font-size: 1.5rem !important;
      }
    }

    &-text-col {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: center;
      align-items: flex-start;
      padding: 3rem;
      min-width: 300px;

      @media screen and (max-width: 400px) {
        min-width: unset;
      }

      h1 {
        font-size: 2rem;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      p {
        font-size: 1.5rem;
      }
    }
    &-img-col {
      flex: 2;
      max-height: 50vh;

      @media screen and (max-width: 1000px) {
        flex: 1;
      }

      @media screen and (max-width: 750px) {
        max-height: unset;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0% 60%;
      }
    }
  }

  .section-full-right {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--gold);
    color: white;

    @media screen and (max-width: 750px) {
      flex-direction: column-reverse;
    }

    &-text-col {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: flex-start;
      padding: 3rem;
      min-width: 300px;

      @media screen and (max-width: 400px) {
        min-width: unset;
      }

      h1 {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 2rem;
      }

      p {
        font-size: 1.5rem;
      }
    }

    &-img-col {
      flex: 2;
      max-height: 50vh;

      @media screen and (max-width: 1000px) {
        flex: 1;
      }
      @media screen and (max-width: 750px) {
        max-height: unset;
      }

      img {
        width: 100%;
        height: 100%;
        min-height: 40vh;
      }
    }
  }

  .section-center-text {
    border: 1px solid rgb(206, 199, 199);
    display: flex;
    padding: 6rem;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 850px) {
      padding: 3rem;
    }

    &-border {
      width: 50%;
      border: 3px solid var(--gold);
      padding: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      text-align: center;

      @media screen and (max-width: 1300px) {
        width: 80%;
      }
      @media screen and (max-width: 850px) {
        width: 90%;
      }

      .diamond {
        background-color: var(--tan);
        background-image: linear-gradient(#b99d42, #f1d15c);
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
      }
    }
  }
}
