.contact {
  min-height: 100vh;
  position: relative;

  &-section {
    padding: 3rem;
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 1200px) {
      padding: 1rem;
    }

    @media screen and (max-width: 1500px) {
      padding: 1rem;
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 60%;

      @media screen and (max-width: 1800px) {
        width: 80%;
      }
      @media screen and (max-width: 1500px) {
        width: 90%;
      }

      h2 {
        text-align: center;
        color: var(--purple);
        letter-spacing: 1px;
      }

      .form-container {
        display: flex;
        @media screen and (max-width: 1200px) {
          flex-direction: column-reverse;
          gap: 20px;
        }
        .form-control {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;
          flex: 1;
          min-width: 700px;

          @media screen and (max-width: 800px) {
            min-width: unset;
          }

          input {
            width: 80%;
            padding: 1rem;
            border: 2px solid #dedede;
            outline: none;
            border-radius: 10px;
            letter-spacing: 1px;
            font-weight: bold;
            font-size: 1.2rem;

            @media screen and (max-width: 551px) {
              font-size: 0.8rem;
            }

            &:active {
              transition: none;
            }

            &:focus-visible {
              border: 2px solid var(--purple);
              color: var(--purple);
            }
          }
          textarea {
            font-size: 1.2rem;
            font-weight: bold;
            letter-spacing: 1px;
            border: 2px solid #dedede;
            width: 80%;
            outline: none;
            resize: none;
            padding: 1rem;
            border-radius: 10px;
            @media screen and (max-width: 551px) {
              font-size: 0.8rem;
            }

            &:focus-visible {
              border: 2px solid var(--purple);
              color: var(--purple);
            }
          }

          .loader {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
            font-weight: bold;
            color: var(--purple);
            gap: 20px;
            height: 90px;
          }

          .send-btn {
            outline: none;
            border-radius: 10px;
            cursor: pointer;
            background-color: white;
            border: 1px solid var(--purple);
            color: var(--purple);
            width: 25%;
            padding: 10px;
            transition: background-color 0.4s;
            margin-bottom: 2rem;
            @media screen and (max-width: 551px) {
              font-size: 0.8rem;
              min-width: 10rem;
            }

            &:hover {
              color: white;
              background-color: var(--purple);
            }
          }
        }
      }

      .contact-details {
        user-select: none;
        flex: 2;
        border: 2px solid #dedede;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 1rem;
        border-radius: 10px;
        height: 100%;
        width: 90%;
        margin: auto;
        font-size: 1.5rem;

        .placeholder {
          display: flex;
          flex-direction: column;
          &::-webkit-scrollbar {
            width: 12px; /* width of the entire scrollbar */
          }

          &::-webkit-scrollbar-track {
            background: white; /* color of the tracking area */
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(--purple); /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            border: 3px solid var(--purple); /* creates padding around scroll thumb */
          }

          a {
            text-decoration: none;
            color: black;
            width: 100%;
            font-size: 1rem;

            &:hover {
              color: var(--purple3);
            }
          }
        }
      }
    }
  }
}
