.services {
  min-height: 100vh;
  position: relative;
  scroll-behavior: smooth;

  .services-offered {
    padding: 3rem;

    h1 {
      text-align: center;
      margin-bottom: 2rem;
    }
    .services-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 3rem;

      .selected {
        border: 1px solid black !important;
        background-color: black !important;
        color: white !important;
      }

      .service {
        cursor: pointer;
        min-width: 250px;
        max-width: 250px;
        padding: 1rem;
        border: 1px solid var(--purple);
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        align-items: center;

        &:hover {
          border: 1px solid black;
          background-color: black;
          color: white;
        }
        h2 {
          font-size: 1rem;
          text-align: center;
        }

        .icon {
          font-size: 4rem;
        }
      }
    }
    .services-display {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 1rem;
      border: 1px solid var(--purple);
      width: 85%;
      margin: auto;
      @media screen and (max-width: 615px) {
        width: 100%;
      }

      h2 {
        font-size: 2rem;
        text-transform: uppercase;
      }

      p {
        width: 70%;
        font-size: 1.5rem;
        @media screen and (max-width: 615px) {
          width: 100%;
        }
      }

      .image-container {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;
        align-items: center;

        img {
          width: 500px;
          height: 500px;

          @media screen and (max-width: 720px) {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .section-full-left {
    display: flex;
    flex-wrap: wrap;
    background-color: black;
    color: white;
    border-bottom: 1px solid white;

    @media screen and (max-width: 750px) {
      flex-direction: column;
    }

    &-text-col {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      align-items: center;
      padding: 3rem;
      min-width: 300px;

      @media screen and (max-width: 400px) {
        min-width: unset;
      }

      h1 {
        font-size: 2rem;
        text-transform: uppercase;
      }

      ul {
        width: 100%;
        margin-bottom: 2rem;
        font-size: 1.5rem;

        li {
          padding-left: 1rem;
        }
      }

      // h1 {
      //   @media screen and (max-width: 950px) {
      //     font-size: 1.2rem;
      //   }
      // }

      // p {
      //   @media screen and (max-width: 950px) {
      //     font-size: 0.8rem;
      //   }
      // }
    }
    &-img-col {
      flex: 2;
      max-height: 50vh;

      @media screen and (max-width: 1000px) {
        flex: 1;
      }

      @media screen and (max-width: 750px) {
        max-height: unset;
      }

      img {
        width: 100%;
        height: 100%;
        min-height: 40vh;
      }
    }
  }
  .btn {
    outline: none;
    border-radius: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    background-color: white;
    border: 1px solid var(--purple);
    color: var(--purple);
    width: 50%;
    padding: 10px;
    transition: background-color 0.4s;
    margin-bottom: 2rem;

    &:hover {
      color: white;
      background-color: var(--purple);
    }

    @media screen and (max-width: 400px) {
      font-size: 1rem;
    }
  }
}
