.hero {
  width: 100%;
  height: 70vh;
  margin-top: 120px;
  display: flex;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 550px) {
    height: 30vh;
  }

  @media screen and (max-width: 400px) {
    margin-top: 100px;
  }

  &-filter {
    background-color: rgba(36, 36, 36);
    transition: opacity 3s;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
  }

  &-header {
    position: absolute;
    left: 50px;
    top: 250px;
    z-index: 11;
    color: white;
    font-size: 3rem;
    transform: translateX(-200%);
    transition: transform 1s;
    font-weight: bold;
    user-select: none;

    @media screen and (max-width: 750px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 530px) {
      font-size: 1.5rem;
      top: 40px;
      left: 30px;
    }
    @media screen and (max-width: 370px) {
      font-size: 1rem;
      left: 20px;
    }
  }
  &-slogan {
    position: absolute;
    left: 70px;
    top: 320px;
    z-index: 11;
    color: white;
    font-size: 1.5rem;
    transform: translateX(-200%);
    transition: transform 1s;
    user-select: none;

    @media screen and (max-width: 750px) {
      font-size: 1.5rem;
      top: 300px;
    }
    @media screen and (max-width: 530px) {
      font-size: 1rem;
      top: 80px;
      left: 60px;
    }
    @media screen and (max-width: 370px) {
      left: 30px;
    }
  }

  &-no-slogan {
    font-size: 3rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: fill07;
    position: relative;
    top: -60px;
  }
}
