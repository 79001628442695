.about {
  min-height: 100vh;
  position: relative;

  .about-section {
    display: flex;
    padding: 3rem;
    @media screen and (max-width: 1213px) {
      flex-direction: column;
    }

    .about-left {
      flex: 2;
      min-width: 550px;

      @media screen and (max-width: 1213px) {
        min-width: 250px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media screen and (max-width: 1213px) {
          max-height: 500px;
        }
      }
    }
    .about-right {
      min-width: 550px;
      flex: 5;
      font-size: 1.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      gap: 1rem;
      padding: 2rem;

      @media screen and (max-width: 1213px) {
        min-width: 250px;
      }
    }
  }

  .meet-the-team {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    background-color: black;

    h1 {
      text-transform: uppercase;
      letter-spacing: 1px;
      color: white;
    }

    .team-box-wrapper {
      width: 100%;
      display: flex;
      gap: 1rem;
      justify-content: center;
      flex-wrap: wrap;

      .team-box {
        flex: 1;
        max-width: 30%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        color: white;
        padding: 2rem;
        min-width: 500px;

        @media screen and (max-width: 530px) {
          min-width: 300px;
        }

        h2 {
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 2rem;
        }

        p {
          font-size: 1.5rem;
        }
      }
    }
  }

  .our-credentials {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    h1 {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 800;
      user-select: none;

      @media screen and (max-width: 400px) {
        font-size: 1.9rem;
      }
    }

    &-logo-container {
      display: flex;
      width: 100%;
      gap: 10px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .img-wrapper {
        display: flex;
        flex: 1;
        padding: 1rem;
        flex-direction: column;
        max-width: 300px;
        align-items: center;
        gap: 10px;

        img {
          width: 250px;
          user-select: none;
          height: 150px;
        }

        p {
          font-weight: bold;
          font-size: 1rem;
          text-align: center;
          user-select: none;
        }
        br {
          user-select: none;
        }
      }
    }
  }
  .insurances {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: black;
    color: white;
    border-bottom: 1px solid white;

    h1 {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 800;
      user-select: none;
    }

    &-logo-container {
      display: flex;
      width: 80%;
      gap: 30px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .img-wrapper {
        display: flex;
        flex: 1;
        padding: 1rem;
        flex-direction: column;
        justify-content: center;
        max-width: 300px;
        align-items: center;
        gap: 10px;
        background-color: white;
        min-width: 300px;
        color: black;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        height: 300px;

        img {
          width: 100%;
          min-height: 150px;
          user-select: none;
        }

        p {
          user-select: none;
        }
      }
    }
  }
}
