.footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  background-color: black;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  .images-row {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem;

    @media screen and (max-width: 580px) {
      justify-content: center;
    }

    img {
      object-fit: cover;
      flex: 1;
      max-width: 200px;
      max-height: 100px;
    }
    .logo-img {
      width: 100%;
      max-width: 300px;
      max-height: 300px;
      flex: 2 !important;
    }
  }

  .contact-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    color: white;
    gap: 10px;
    max-width: 500px;

    @media screen and (max-width: 980px) {
      max-width: unset;
    }

    & > p {
      width: 70%;
      margin-left: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      @media screen and (max-width: 800px) {
        margin-left: unset;
        margin: auto;
      }

      & > a {
        display: flex;
        text-decoration: none;
        color: white;

        &:hover {
          color: var(--gold);
        }
      }
    }
  }

  .bottom-bar {
    display: flex;
    color: white;
    padding-top: 1rem;
    border-top: 1px solid white;
    gap: 10px;
    text-align: center;

    @media screen and (max-width: 865px) {
      flex-direction: column;
    }

    &-left {
      flex: 1;
    }

    &-right {
      flex: 1;
    }
  }
}
