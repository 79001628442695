@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-share.css");

.project {
  .description {
    margin: 0 3rem 3rem 3rem;
    background-color: black;
    padding: 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    word-break: break-word;
    gap: 10px;

    ul {
      margin-left: 1rem;
      font-size: 1.5rem;
      margin-bottom: 1rem;
      @media screen and (max-width: 600px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 400px) {
        font-size: 0.8rem;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 2rem;

      @media screen and (max-width: 600px) {
        font-size: 1.5rem;
      }

      @media screen and (max-width: 400px) {
        font-size: 1rem;
      }
    }

    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;

      @media screen and (max-width: 600px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 400px) {
        font-size: 0.8rem;
      }
    }

    p {
      font-size: 1.5rem;
      margin-bottom: 1rem;

      @media screen and (max-width: 600px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 400px) {
        font-size: 0.8rem;
      }
    }

    & > button {
      outline: none;
      border-radius: 10px;
      cursor: pointer;
      background-color: white;
      border: 1px solid var(--purple);
      color: var(--purple);
      width: 16rem;
      padding: 10px;
      transition: background-color 0.4s;
      margin-bottom: 2rem;
      font-size: 1.5rem;

      &:hover {
        color: white;
        background-color: var(--purple);
      }

      @media screen and (max-width: 600px) {
        font-size: 1.5rem;
      }

      @media screen and (max-width: 400px) {
        font-size: 0.8rem;
        width: 10rem;
      }
    }
  }

  .gallery {
    width: 100%;
  }
}
