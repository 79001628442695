.projects {
  min-height: 100vh;
  position: relative;

  .gallery-section {
    padding: 3rem;
    display: flex;
    flex-direction: column;

    h2 {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 2rem;
    }

    .underline {
      width: 100%;
      height: 2px;
      background-color: black;
      margin-bottom: 3rem;
    }

    .gallery-grid {
      width: 100%;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .gallery-item {
        cursor: pointer;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
        flex: 1;
        max-width: 500px;
        min-width: 500px;
        padding: 10px;
        border: 1px solid transparent;

        @media screen and (max-width: 535px) {
          max-width: 300px;
          min-width: 300px;
        }

        &:hover {
          color: var(--purple);
          font-weight: bold;
          letter-spacing: 1px;
        }

        img {
          width: 490px;
          height: 490px;
          @media screen and (max-width: 535px) {
            width: 290px;
            height: 290px;
          }

          &:hover {
            -webkit-filter: blur(2px);
            filter: blur(2px);
          }
        }
      }
    }
  }
}
