.navbar {
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;

  .top-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-bottom: 1px solid white;
    gap: 5px;

    @media screen and (max-width: 400px) {
      display: none;
    }

    &-wrapper {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--gold);
      svg {
        &:first-of-type {
          margin-right: 1rem;
        }
      }
      span {
        margin-right: 1rem;
      }
    }
  }

  .container {
    display: flex;
    height: 100px;
    padding: 10px 2rem;
    .brand {
      height: 100%;
      width: 9rem;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .inner {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      .mobile-menu {
        font-size: 3rem;
        color: white;
        margin: auto 0;
        cursor: pointer;

        @media screen and (min-width: 721px) {
          display: none;
        }
      }

      ul {
        display: flex;
        align-items: center;
        list-style: none;
        gap: 2rem;

        @media screen and (max-width: 720px) {
          flex-direction: column;
          padding: 4rem 1rem 0 1rem;
          font-size: 2rem;
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          top: 8.9rem;
          background-color: black;
          border-top: 1px solid white;

          @media screen and (max-width: 400px) {
            top: 6rem;
          }
        }

        li {
          font-size: 1rem;
          color: white;
          display: flex;
          flex-direction: column;
          gap: 2px;
          font-size: inherit;
          transition: transform 0.2s;

          &:hover {
            transform: scale(1.1);
            color: var(--gold);
            font-weight: bold;
          }
          a {
            letter-spacing: 1px;
            text-decoration: none;
            color: inherit;
          }

          .underline {
            height: 2px;
            width: 100%;
            background-color: transparent;

            &-hov {
              background-color: var(--gold);
            }
          }
        }
      }
    }
  }
}
