.modal {
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: none;

  .overlay {
    background-color: black;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0.9;
  }

  .prevbtn {
    position: absolute;
    top: 45%;
    left: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    z-index: 102;

    &:hover {
      background-color: red;
      color: white;
    }
  }

  .nextbtn {
    position: absolute;
    top: 45%;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    z-index: 102;

    &:hover {
      background-color: red;
      color: white;
    }
  }

  .closebtn {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    z-index: 102;
    width: 3rem;

    &:hover {
      background-color: red;
      color: white;
    }
  }

  img {
    z-index: 101;
    width: 80%;
    height: 80%;
    overflow: none;
    object-fit: cover;

    @media screen and (max-width: 900px) {
      width: 90%;
      height: 60%;
    }

    @media screen and (max-width: 400px) {
      width: 80%;
      height: 50%;
      max-height: 500px;
    }
  }
}

.image-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  img {
    width: 250px;
    height: 250px;
    display: block;
    margin-bottom: 3rem;
    cursor: pointer;

    &:hover {
      -webkit-backdrop-filter: blur(2px);
      filter: blur(2px);
    }
  }
}
